<template>
  <div class="relative">
    <div
      class="absolute top-0 left-0 w-full h-full"
      @click="toggle"
    >
      <video
        ref="video"
        :src="screensaver.video"
        class="absolute top-0 left-0 w-full h-full object-cover"
        muted
        loop
      />
    </div>

    <transition name="fade-in">
      <div
        v-if="showOverlay"
        class="fixed z-50 w-full bg-white min-h-screen flex items-center justify-center">
        <div class="text-center">
          <img
            src="@/assets/images/logo.svg"
            alt="Global Pay"
            class="inline-block w-32 lg:w-44 mb-6"
          />

          <div>
            <button
              class="border border-blue rounded-md py-2 px-5
              bg-blue text-sm font-medium text-white uppercase
                transition-colors
              hover:bg-white hover:text-blue
                focus:outline-none focus:bg-white focus:text-blue
                active:bg-white active:text-blue"
              @click="start">
              Touch To Start
            </button>
          </div>
        </div>
      </div>
    </transition>

    <div class="relative z-10 h-screen flex flex-col justify-end pt-24 pb-6 lg:pb-10">
      <div class="px-4 2xl:px-8">
        <h1
          class="sr-only">
          See how we`re creating amazing moments across the stadium
        </h1>

        <div class="flex items-center justify-between">
          <button
            class="img-btn mr-4"
            @click="openVideo"
          >
            <div class="img-btn__row">
              <div class="img-btn__img">
                <img
                  src="@/assets/images/video.jpg"
                  alt=""
                />

                <span class="btn-play"></span>
              </div>

              <div class="img-btn__btn">
                Watch the video
              </div>
            </div>
          </button>

          <button
            class="img-btn"
            @click="openStadium"
          >
            <div class="img-btn__row">
              <div class="img-btn__img">
                <img
                  src="@/assets/images/stadium-xs.jpg"
                  alt=""
                />
              </div>

              <div class="img-btn__btn">
                Explore the stadium
              </div>
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getters } from '@/store';

export default {
  name: 'HomePage',
  data() {
    return {
      isPlaying: false,
      showOverlay: false,
    };
  },
  computed: {
    ...mapGetters([
      getters.content.screensaver,
    ]),
  },
  mounted() {
    this.start();
  },
  methods: {
    start() {
      this.showOverlay = false;
      this.play();
    },
    openStadium() {
      this.$router.push({
        name: 'stadium.index',
        params: { showOverlay: true },
      });
    },
    openVideo() {
      this.$router.push({
        name: 'video.index',
      });
    },
    play() {
      this.$refs.video.play();
      this.isPlaying = true;
      this.showOverlay = false;
    },
    pause() {
      this.$refs.video.pause();
      this.isPlaying = false;
    },
    toggle() {
      if (!this.isPlaying) {
        this.play();
      } else {
        this.pause();
      }
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/_mixins';
@import '@/assets/scss/_variables';

.img-btn {
  @include hover {
    .img-btn__img {
      img {
        transform: scale(1.05);
      }
    }
  }

  &__row {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__img {
    position: relative;
    margin-bottom: 1rem;
    border-radius: 50%;
    width: 10rem;
    height: 10rem;
    overflow: hidden;

    @include respond-below(lg) {
      width: 6rem;
      height: 6rem;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transform: scale(1.025);
      transition: transform 0.3s;
    }

    .btn-play {
      position: absolute;
      z-index: 10;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__btn {
    display: inline-block;
    border-radius: 2.25rem;
    padding: 1.4rem 3.5rem;
    background-color: $color-white;
    font-size: 1.2rem;
    font-weight: 700;
    color: $color-blue;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    @include respond-below(lg) {
      padding: 0.75rem 1.5rem;
    }
  }
}
</style>
